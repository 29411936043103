import React, { useState, useEffect } from "react";
import { Button, Upload, message, Radio } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics";
import { uploadTypeCategory, uploadTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import uploadFileToS3 from "../../api/UploadPdf";
import AdvertiseMentModal from "../../helpers/AdvertisementModal";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";

const CustomRadio = ( { option, selected, onSelect } ) => {
    console.log( "Radio Value: ", option.id );
    console.log( "Is Checked: ", selected?.id === option.id );
    return (
        <Radio
            className="flex items-center cursor-pointer bg-white p-2 rounded-md"
            value={ option.id }
            checked={ selected?.id === option.id }
            onChange={ () => { console.log( 'Radio button clicked' ); onSelect( option ); } }
        >
            { option.value }
        </Radio>
    );
};


const MultiChoiceStepLink = ( { question, selected, onSelect, onNextUpload, onBack } ) => {
    const [selectedOption, setSelectedOption] = useState( null );
    const [uploadedFile, setUploadedFile] = useState( null );
    const [linkInput, setLinkInput] = useState( '' );
    const [isOptionSelected, setIsOptionSelected] = useState( false );
    const [s3Url, setS3Url] = useState( null );
    const [showAdvertisement, setShowAdvertisement] = useState( false );

    useEffect( () => {
        console.log( "Current Selected Option: ", selectedOption );
        // Check if an option is selected
        setIsOptionSelected( true );
    }, [selectedOption] );

    const handleLinkInputChange = ( e ) => {
        setLinkInput( e.target.value );
        setIsOptionSelected( true );
    };


    const handleFileUpload = ( file ) => {
        // Just set the file, don't upload yet
        setUploadedFile( file );
        setIsOptionSelected( true )
    };

    //const handleNextUpload = async () => {
    //    setIsOptionSelected( true )
    //    // Upload file to S3 if required
    //    let url = await uploadFileToS3( uploadedFile );
    //    setS3Url( url );
    //    // Call the onNextUpload function with S3 URL
    //    onNextUpload( url );
    //};

    const handleNext = async () => {
        if ( uploadedFile ) {
            // Upload file to S3 if a file is selected
            let url = await uploadFileToS3( uploadedFile );
            setS3Url( url );
            onNextUpload( url );
        } else if ( linkInput ) {
            // If a link is pasted
            onNextUpload( linkInput );
        } else {
            onNextUpload( "" )
        }
    };


    const handleSelect = ( option ) => {
        onSelect( option );
        setIsOptionSelected( true );
    };

    return (
        <>
            <div className="mt-2">
                <div className="justify-content">
                    <QuestionAndDescription question={ question } />
                    <div className="space-y-2">
                        { question.options.map( ( option ) => (
                            <CustomRadio
                                key={ option.id }
                                option={ option }
                                selected={ selected }
                                onSelect={ handleSelect }
                            />
                        ) ) }
                    </div>
                    <>
                        <Upload
                            accept=".pdf"
                            beforeUpload={ ( file ) => {
                                handleFileUpload( file );
                                return false;
                            } }
                        >
                            <Button className="mt-2" icon={ <UploadOutlined /> }>
                                Upload PDF
                            </Button>
                        </Upload>
                        <div className="mt-2">
                            <textarea
                                className="rounded-md bg-white w-3/4 sm:w-full lg:w-3/4 mr-4 p-1 border"
                                style={ { resize: 'none' } }
                                placeholder={ question?.placeholder_text }
                                value={ linkInput }
                                onChange={ handleLinkInputChange }
                                pattern="https?://.+"
                                title="Enter a valid URL."
                            />
                        </div>
                    </>
                </div>
            </div>
            <div className="flex flex-row gap-2 justify-end sm:mt-4 md:mt-4 lg:mt-[20%]">
                {
                    question?.question_optional === "optional" && (
                        <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ handleNext } >
                            Skip
                        </button>
                    )
                }
                <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack }>
                    Back
                </button>
                <button
                    className={ `px-4 py-2 rounded-md text-white ${ isOptionSelected ? 'bg-blue-500' : 'bg-blue-300' }` }
                    onClick={ isOptionSelected ? handleNext : null }
                    style={ isOptionSelected ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                >
                    Next
                </button>
            </div>
        </>
    );
};

export default MultiChoiceStepLink;
