import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import { notification, Spin } from 'antd';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import thankYouanimation from '../../assets/Animation/thankyou_animation.json'
import Lottie from 'lottie-react';
import ThankYourPageThree from './ThankYouPageThree';

export const ThankYouWeAce = () => {

    const { client_name } = useParams();
    const student_name = localStorage.getItem( 'studentName' ) || "abc";
    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";
    const [studentRollNumber, setStudentRollNumber] = useState( null );
    const location = useLocation();
    const isWide = useMedia( '(max-width: 500px)' );

    const params = new URLSearchParams( location.search );
    const template_id = params.get( 'template_id' );
    const formConfigRedux = useSelector( state => state.formConfiguration );

    const formConfig = formConfigRedux?.form_config;
    const clientid = formConfig?.client_id;
    const formid = formConfig?.form_id;

    const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();

    useEffect( () => {
        Object.keys( localStorage ).forEach( ( key ) => {
            if ( key !== 'student_roll_number' && key !== 'clientId' && key !== 'form_id' && key !== 'userAssessmentResponseID' ) {
                localStorage.removeItem( key );
            }
        } );
    }, [] )

    useEffect( () => {
        if ( studentRollNumber ) {
            generatePdfReport( studentRollNumber, formid )
        }
    }, [studentRollNumber] )

    const handleDownloadReport = async () => {
        if ( pdfReportGenerating ) {
            notification.info( {
                message: 'Please wait, we\'re generating your pdf report',
            } )
        }
        if ( pdfReport ) {
            try {
                const url = new URL( pdfReport );
                window.open( url, '_blank' );
            } catch ( e ) {
                console.error( 'Invalid PDF report URL:', pdfReport );
            }
        }
    };

    useEffect( () => {
        const student_roll_number = localStorage.getItem( 'student_roll_number' ) || localStorage.getItem( 'user_id' )
        setStudentRollNumber( student_roll_number )
    }, [localStorage.getItem( 'student_roll_number' )] );

    return (
        <>
            <ThankYourPageThree
                handleReport={ handleDownloadReport }
            />
        </>
    );
};

