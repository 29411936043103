import React, { useEffect, useState } from 'react';
import LeftSideBar from '../LeftSideBar';
import { Spin, Tooltip } from 'antd';
import useFetchFormsByUserId from '../../api/FetchFormsUserId';
import { useParams, Link, useLocation } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled, LockFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setFormConfiguration } from '../../redux/student/studentSlice';
import InstructionModal from './InstructionModal';

const TestSelection = () => {

    const { response: formsData, isLoading: formsdataLoading, FetchUserForms } = useFetchFormsByUserId();
    const { client_name } = useParams();
    const clientConfig = useSelector( state => state.clientConfiguration );

    const [isModalOpen, setIsModalOpen] = useState( false );
    const [currentTestId, setCurrentTestId] = useState( null );
    const [videoCompletionStatus, setVideoCompletionStatus] = useState( {} );

    const openModal = ( testId ) => {
        setCurrentTestId( testId );
        setIsModalOpen( true );
    };

    const closeModal = () => {
        setIsModalOpen( false );
        setCurrentTestId( null );
    };

    const setIsVideoCompleted = ( testId, status ) => {
        setVideoCompletionStatus( prevState => ( {
            ...prevState,
            [testId]: status
        } ) );
    };

    const location = useLocation();
    const query = new URLSearchParams( location.search );
    const user_email = query.get( 'user_email' );
    const user_name = query.get( 'user_name' );
    const user_id = query.get( 'user_id' );
    const dispatch = useDispatch();

    useEffect( () => {
        Object.keys( localStorage ).forEach( ( key ) => {
            if ( key !== 'student_roll_number' && key !== 'user_id' ) {
                localStorage.removeItem( key );
            }
        } );
    }, [] )

    useEffect( () => {
        if ( formsData ) {
            localStorage.setItem( 'user_id', formsData.user_id );
        }
    }, [formsData] )

    useEffect( () => {
        if ( client_name ) {
            sessionStorage.removeItem( 'formConfiguration' );
            FetchUserForms( client_name, user_id, 'test' )
            dispatch( setFormConfiguration( '' ) );
        }
    }, [client_name] )

    return (
        <div className="container-fluid">
            <div className="row row-height">
                {
                    formsdataLoading &&
                    <div className="overlay">
                        <Spin size="large" />
                    </div>
                }
                <LeftSideBar />
                <div className="col-xl-8 col-lg-8 content-right" id="start">
                    <>
                        <div className="container mx-auto p-4">
                            <div className="flex items-center justify-center w-full flex-col">
                                <div className="w-full overflow-x-auto">
                                    <table className="table-auto w-full">
                                        <tbody>
                                            { formsData?.forms?.map( ( test, index ) => (
                                                <React.Fragment key={ test.test_id }>
                                                    { currentTestId === test.test_id && (
                                                        <InstructionModal
                                                            isModalOpen={ isModalOpen }
                                                            handleCancel={ closeModal }
                                                            setIsVideoCompleted={ ( testId, status ) => setIsVideoCompleted( testId, status ) }
                                                            youtubeURL="https://youtu.be/cxQMLCtoi_w?si=TNwmLEZHeXtqNft8"
                                                            testId={ test.test_id }
                                                        />
                                                    ) }
                                                    <tr>
                                                        <td className="px-2 sm:px-4 py-3 text-center text-base sm:text-lg border-2">Test { index + 1 }</td>
                                                        <td className="px-2 sm:px-4 py-3 text-base sm:text-lg text-left border-2">{ test.form_name }</td>
                                                        <td className="px-2 sm:px-4 py-3 text-center text-base sm:text-lg border-2">
                                                            <button
                                                                type='button'
                                                                className={ `text-white px-8 rounded-lg py-2` }
                                                                style={ { cursor: test.is_completed === 3 ? 'not-allowed' : 'pointer', backgroundColor: clientConfig.primary_color } }
                                                                onClick={ () => {
                                                                    if ( test.is_completed !== 3 ) {
                                                                        window.location.href = `/${ client_name }/test/${ test?.test_id }/instructions/?user_email=${ user_email }&user_name=${ user_name }&user_id=${ user_id }`;
                                                                    }
                                                                } }

                                                            >
                                                                Take Test
                                                            </button>
                                                        </td>
                                                        {/*<td className="px-2 sm:px-4 py-3 text-center text-base sm:text-lg border-2">
                                                            <button
                                                                onClick={ () => openModal( test.test_id ) }
                                                                className='text-white bg-blue-500 px-2 rounded-lg py-2'>
                                                                Instructions
                                                            </button>
                                                        </td>*/}
                                                        <td className="px-2 sm:px-4 py-3 text-center text-base sm:text-lg border-2">
                                                            { test.is_completed === 3 ? (
                                                                <CheckCircleFilled style={ { color: 'green', fontSize: '24px' } } />
                                                            ) : (
                                                                <CloseCircleFilled style={ { color: 'red', fontSize: '24px' } } />
                                                            ) }
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ) ) }
                                        </tbody>
                                    </table>
                                </div>
                                { formsData?.forms?.every( ( test ) => test.is_completed === 3 ) ? (
                                    <Link to={ `/${ client_name }/thank_you/?completed=true` }>
                                        <button
                                            style={ { backgroundColor: clientConfig.primary_color } }
                                            className="text-white text-center align-center px-2 rounded-lg py-2 mt-4 text-base sm:text-lg">View My Report</button>
                                    </Link>
                                ) : (
                                    <div className="flex items-center justify-center mt-4">
                                        <Tooltip title="Please complete all the tests" placement='bottom' arrow={ false }>
                                            <button className="text-black bg-yellow-300 opacity-50 px-2 rounded-lg py-2 text-base sm:text-lg text-center align-center">
                                                <LockFilled style={ { color: 'black' } } className='mr-2 align-middle' />
                                                View My Report
                                            </button>
                                        </Tooltip>
                                    </div>
                                ) }
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};

export default TestSelection
