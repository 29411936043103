import React from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import MultiStepForm from '../../MultiStepForm/MainStepForm';
import GoogleAnalyticsApp from "../../api/FetchAnalyticsReport";
import { GoogleOAuthProvider } from "@react-oauth/google";
import InitializeGoogleAnalytics from "../GoogleAnalytics/index";
import { CLIENT_ID } from '../constants/constants';
import '../../styles.css';
import EventAnalyticsData from "../../api/FetchEventData";
import FormSelector from "../../MultiStepForm/FormSelector/FormSelector";
import Review from "../../MultiStepForm/ReviewPage/Review";
import ThankYouPage from "../../MultiStepForm/ThankYouPage/ThankYou";
import TestTermsAndConditions from "../../MultiStepForm/TermsAndConditions/TermsAndConditions";
import SignIn from "../../MultiStepForm/SignInPage/Signin";
import Instructions from "../../MultiStepForm/InstructionPage/Instructions";
import CouponCodeVerification from "../../MultiStepForm/CouponCode/VerificationCouponCode";
import TestSelection from "../../MultiStepForm/TestsSectionSelection/TestSelection";
import EmailVerification from "../../MultiStepForm/Verification/EmailVerification";
import MobileVerification from "../../MultiStepForm/Verification/PhoneNumberVerification";
import FlowBlocksChange from "../../MultiStepForm/FlowBlocksChange/FlowBlocksChange";

const RoutesContainer = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <TestTermsAndConditions /> } />
                <Route path="/:client_name" element={ <FormSelector /> } />
                <Route path="/:client_name/:test_type/:testId/instructions" element={ <Instructions /> } />
                <Route path="/:client_name/:test_type/:testId" element={ <MultiStepForm /> } />
                <Route path="/:client_name/:test_type/:testId/email_verification" element={ <EmailVerification /> } />
                <Route path="/:client_name/:test_type/:testId/phone_verification" element={ <MobileVerification /> } />
                <Route path="/:client_name/:test_type/:testId/flow" element={ <FlowBlocksChange /> } />
                <Route path="/:client_name/:test_type/signup" element={ <SignIn /> } />
                <Route path="/:client_name/:test_type/signin" element={ <SignIn /> } />
                <Route path="/:client_name/test/:grade/:id" element={ <MultiStepForm /> } />
                <Route path="/:client_name/thank_you" element={ <ThankYouPage /> } />
                <Route path="/:client_name/test/selection" element={ <TestSelection /> } />
                <Route path="/:client_name/test/:grade/thank_you" element={ <ThankYouPage /> } />
                <Route path="/:client_name/verification/coupon_code" element={ <CouponCodeVerification /> } />
                <Route path="/google_analytics"
                    element={ <GoogleOAuthProvider
                        clientId={ CLIENT_ID }>
                        <GoogleAnalyticsApp />
                    </GoogleOAuthProvider> } />

                <Route path="/event_analytics"
                    element={ <GoogleOAuthProvider
                        clientId={ CLIENT_ID }>
                        <EventAnalyticsData />
                    </GoogleOAuthProvider> } />
                <Route path="/ani" element={ <Review /> } />
            </Routes>
        </BrowserRouter>
    );
}

export default RoutesContainer;