import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { notification } from "antd";
import {
    ClockCircleFilled,
} from '@ant-design/icons';
import updateUserResponse from '../../api/useUpdateUserResponse'

const TestTimer = ( { testTimeInSeconds, isWide, currentComponentNext } ) => {
    const storageId = 'countdown-timer';

    const clientConfig = useSelector( ( state ) => state.clientConfiguration );
    const isConsentForm = useSelector( ( state ) => state.consentForm.isConsentForm );
    const userAssessmentResponseID = localStorage.getItem( 'userAssessmentResponseID' );
    const { updateResponse } = updateUserResponse();

    // Initial values based on current time
    const initialStartTime = Date.now();
    const initialEndTime = initialStartTime + testTimeInSeconds * 1000;

    // Retrieve saved end time from localStorage or calculate a new one if not present
    const savedEndTime = localStorage.getItem( storageId );
    const [endTime, setEndTime] = useState( () => savedEndTime ? parseInt( savedEndTime, 10 ) : initialEndTime );

    // Function to calculate time left
    const calculateTimeLeft = ( endTime ) => {
        const currentTime = Date.now();
        const timeLeft = Math.max( endTime - currentTime, 0 ) / 1000;
        return timeLeft;
    };

    // Set initial time left based on end time
    const [timeLeft, setTimeLeft] = useState( calculateTimeLeft( endTime ) );
    const [notificationShown, setNotificationShown] = useState( false );

    useEffect( () => {
        if ( isConsentForm ) return;

        // Interval to update time left every second
        const interval = setInterval( () => {
            const newTimeLeft = calculateTimeLeft( endTime );
            setTimeLeft( newTimeLeft );

            if ( newTimeLeft === 0 ) {
                clearInterval( interval );
                updateResponse( { id: userAssessmentResponseID, is_completed: 3 } )
                currentComponentNext();
            }

            if ( newTimeLeft < 600 && !notificationShown ) {
                notification.warning( {
                    message: 'Hurry Up!',
                    description: 'Your test is about to end!',
                    duration: null,
                } );
                setNotificationShown( true );
            }
        }, 1000 );

        return () => clearInterval( interval );
    }, [endTime, notificationShown, isConsentForm] );

    useEffect( () => {
        if ( !isConsentForm ) {
    // Only set end time and store it if it's not retrieved from localStorage
            if ( !savedEndTime ) {
                const newEndTime = initialStartTime + testTimeInSeconds * 1000;
                setEndTime( newEndTime );
                localStorage.setItem( storageId, newEndTime.toString() );
                setTimeLeft( calculateTimeLeft( newEndTime ) );
            } else {
                setTimeLeft( calculateTimeLeft( parseInt( savedEndTime, 10 ) ) );
            }
        }
    }, [isConsentForm, testTimeInSeconds] );

    const timerColor = timeLeft < 600 ? 'text-red-600' : 'text-green-600'; // Less than 10 minutes

    const formatTime = () => {
        const minutes = Math.floor( timeLeft / 60 );
        const seconds = Math.floor( timeLeft % 60 );
        return `${ minutes }:${ seconds < 10 ? '0' : '' }${ seconds }`;
    };

    const displayTime = () => {
        if ( isConsentForm ) {
            const minutes = Math.floor( testTimeInSeconds / 60 );
            return `${ minutes }:00`;
        }
        return formatTime();
    };

    return (
        <p className={ `${ isWide ? 'text-2xl w-full' : 'text-lg w-fit' } text-black font-semibold` }>
            Time Left :{ " " }
            <span className={ `${ isWide ? 'text-2xl' : 'text-lg' } ${ timerColor }` }>
                { displayTime() }
            </span>
        </p>
    );
};

export default TestTimer;
