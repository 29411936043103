import React from 'react'
import Lottie from 'lottie-react';
import thankYouanimation from '../../assets/Animation/thankyou_animation.json'
import thankyouImage from '../../assets/Animation/thankyoupageimage.png';
import { useSelector } from 'react-redux';
import { dynamicData } from '../../helpers/data';
import { useMedia } from 'react-use';
import { Image } from 'antd';

const ThankYouPageFour = ( { handleReport, handlementorshipbooking } ) => {
    const formConfigRedux = useSelector( state => state.formConfiguration );
    const clientConfig = useSelector( state => state.clientConfiguration );
    const isWide = useMedia( '(max-width: 500px)' );

    const formConfig = formConfigRedux?.form_config;
    const clientid = formConfig?.client_id

    let thankYouPage =
        formConfig?.main_test_content?.thank_you_page ||
        dynamicData[clientid]?.main_test_content?.thank_you_page ||
        dynamicData.default?.main_test_content?.thank_you_page;

    let heading = thankYouPage?.heading || "";
    let sub_heading_one = thankYouPage?.sub_heading_one || "";
    let sub_heading_two = thankYouPage?.sub_heading_two || "";
    let sub_heading_three = thankYouPage?.sub_heading_three || "";


    return (
        <>
            {
                isWide ?
                    <div className="h-screen  absolute bottom-0">
                        <Lottie animationData={ thankYouanimation } loop={ 0 } autoplay style={ { width: 'screen', height: 'screen' } } />
                    </div>
                    :
                    <div className="min-h-screen absolute bottom-0">
                        <Lottie animationData={ thankYouanimation } loop={ 0 } autoplay style={ { width: '800px', height: 'screen' } } />
                    </div>
            }

            <div className="text-center lg:w-[90%] sm:w-full flex flex-col justify-around items-center font-poppins h-full">
                <div className='flex flex-col items-center '>
                    <p className="lg:text-5xl sm:text-2xl text-black font-semibold mb-4">{ heading }</p>
                    <p className="lg:text-2xl sm:text-lg text-black font-semibold lg:w-2/3 sm:w-full mb-4">{ sub_heading_one }</p>
                    <button className="text-white text-center rounded-lg lg:py-3 lg:px-4 sm:p-3 mb-2 lg:text-xl sm:text-base z-50" style={ { backgroundColor: clientConfig.primary_color } } onClick={ handleReport }>
                        Go to your PERSONALISED report
                    </button>
                </div>

                <div className='flex lg:flex-row sm:flex-col sm:justify-center gap-[1rem] items-center w-full shadow-md rounded-lg lg:p-[3rem] sm:p-[1rem]' style={ { backgroundColor: clientConfig.primary_color } }>
                    <div className='flex justify-center'>
                        <Image src={ thankyouImage } preview={false} width={180}/>
                    </div>
                    <div className='flex flex-col lg:text-left sm:text-center'>
                        <p className="lg:text-2xl sm:text-xl text-white font-semibold mb-2">{ sub_heading_two }</p>
                        <p className="lg:text-xl sm:text:xl text-white font-semibold mb-[2rem]">{ sub_heading_three }</p>
                        <button onClick={ handlementorshipbooking } className="bg-white text-center rounded-lg lg:py-3 lg:px-4 sm:p-3 mb-2 lg:text-xl sm:text-base font-medium lg:w-fit sm:w-full z-50" style={{color :clientConfig.primary_color }}>
                            Book Your Mentorship Session!
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ThankYouPageFour