import { createSlice } from '@reduxjs/toolkit';

// Student Slice
export const studentSlice = createSlice( {
    name: 'student',
    initialState: {
        isStudent: false,
    },
    reducers: {
        setIsStudent: ( state, action ) => {
            state.isStudent = action.payload;
        },
    },
} );

export const { setIsStudent } = studentSlice.actions;

// Parent Slice
export const parentSlice = createSlice( {
    name: 'parent',
    initialState: {
        isParent: false,
    },
    reducers: {
        setIsParent: ( state, action ) => {
            state.isParent = action.payload;
        },
    },
} );

export const { setIsParent } = parentSlice.actions;

//school regiustration slice
export const schoolRegistration = createSlice( {
    name: 'schoolRegistration',
    initialState: {
        isSchoolRegistration: false,
    },
    reducers: {
        setIsSchoolRegistration: ( state, action ) => {
            state.isSchoolRegistration = action.payload;
        },
    },
} );

export const { setIsSchoolRegistration } = schoolRegistration.actions;

//student regiustration slice
export const studentRegistration = createSlice( {
    name: 'studentRegistration',
    initialState: {
        isStudentRegistration: false,
    },
    reducers: {
        setIsStudentRegistration: ( state, action ) => {
            state.isStudentRegistration = action.payload;
        },
    },
} );

export const { setIsStudentRegistration } = studentRegistration.actions;

// Create a function to get the initial state based on localStorage
const getInitialState = () => {
    const storedQuestionNumber = localStorage.getItem( 'questionNumber' );
    return {
        questionNumber: storedQuestionNumber ? parseInt( storedQuestionNumber, 10 ) : 0,
    };
};

const currentQuestionSlice = createSlice( {
    name: 'currentQuestion',
    initialState: {
        questionNumber: 0
    },
    reducers: {
        setQuestionNumber: ( state, action ) => {
            state.questionNumber = action.payload;
            localStorage.setItem( 'questionNumber', action.payload );
        },
        resetQuestionNumber: ( state ) => {
            state.questionNumber = 0;
            localStorage.removeItem( 'questionNumber' );
        },
    },
} );

export const { setQuestionNumber, resetQuestionNumber } = currentQuestionSlice.actions;

//school regiustration slice
export const consentForm = createSlice( {
    name: 'consentForm',
    initialState: {
        isConsentForm: true,
    },
    reducers: {
        setIsConsentForm: ( state, action ) => {
            state.isConsentForm = action.payload;
        },
    },
} );

export const { setIsConsentForm } = consentForm.actions;

//school regiustration slice
const isFormSubmittedLocalStorage = localStorage.getItem( 'isFormSubmitted' );

export const formSubmitted = createSlice( {
    name: 'formSubmitted',
    initialState: {
        isFormSubmitted: isFormSubmittedLocalStorage !== null ? JSON.parse( isFormSubmittedLocalStorage ) : false,
    },
    reducers: {
        setIsFormSubmitted: ( state, action ) => {
            state.isFormSubmitted = action.payload;
        },
    },
} );


export const { setIsFormSubmitted } = formSubmitted.actions;

const initialState = {
    textAnswers: JSON.parse( localStorage.getItem( 'textAnswers' ) ) || [],
};

//answers slice
export const answersSlice = createSlice( {
    name: 'answersSlice',
    initialState,
    reducers: {
        updateAnswerIndex: ( state, action ) => {
            const questionIndex = action.payload;
            state.textAnswers[questionIndex] = true;
            localStorage.setItem( 'textAnswers', JSON.stringify( state.textAnswers ) );
        },
        resetAnswerStatus: ( state, action ) => {
            const questionIndex = action.payload;
            state.textAnswers[questionIndex] = false;
            localStorage.setItem( 'textAnswers', JSON.stringify( state.textAnswers ) );
        },
        resetState: ( state ) => {
            state.textAnswers = [];
            localStorage.removeItem( 'textAnswers' );
        },
    },
} );

export const { updateAnswerIndex, resetAnswerStatus, resetState } = answersSlice.actions;

//school regiustration slice

const isLoadingScreenLocalStorage = localStorage.getItem( 'isLoadingScreen' );

export const loadingScreen = createSlice( {
    name: 'loadingScreen',
    initialState: {
        isLoadingScreen: isLoadingScreenLocalStorage !== null ? JSON.parse( isLoadingScreenLocalStorage ) : false,
    },
    reducers: {
        setIsLoadingScreen: ( state, action ) => {
            state.isLoadingScreen = action.payload;
        },
    },
} );

export const { setIsLoadingScreen } = loadingScreen.actions;

//Required Questions Attempted slice
export const requiredQuestionsAttempted = createSlice( {
    name: 'requiredQuestionsAttempted',
    initialState: {
        isRequiredQuestionsAttempted: false,
    },
    reducers: {
        setIsRequiredQuestionsAttempted: ( state, action ) => {
            state.isRequiredQuestionsAttempted = action.payload;
        },
    },
} );

export const { setIsRequiredQuestionsAttempted } = requiredQuestionsAttempted.actions;

export const clientConfiguration = createSlice( {
    name: 'clientConfiguration',
    initialState: {
        primary_color: '',
        secondary_color: '',
        tertiary_color: '',
        logo: '',
        email_provider: '',
        payment_provider: '',
        tag_line: '',
        favicon: '',
        description: '',
        id: '',
        client_id: '',
    },
    reducers: {
        setClientConfiguration: ( state, action ) => {
            return action.payload;
        },
    },
} );

export const { setClientConfiguration } = clientConfiguration.actions;

export const formConfiguration = createSlice( {
    name: 'formConfiguration',
    initialState: {
        heading: "",
        sub_heading: "",
        image_link: "",
        additional_text: "",
        is_left_sidebar_visible: "",
        test_verification_mobile: "",
        coupon_code_verification: "",
        view_report: "",
        is_mobile_verification: "",
        is_email_verification: "",
        is_loading_screen: "",
        is_pagination: "",
        is_question_navigation: "",
        proceed_to_test: "",
        verify_with_email: "",
        id: "",
        form_id: "",
        client_id: "",
        form_usage_type: "",
        form_timer: "",
        question_layout: "",
        per_question_timer: "",
        overall_timer: "",
    },
    reducers: {
        setFormConfiguration: ( state, action ) => {
            return action.payload;
        },
    },
} );

export const { setFormConfiguration } = formConfiguration.actions;


const initialStateForFlows = {
    flowData: JSON.parse( sessionStorage.getItem( 'flowData' ) ) || [],
    currentFlowIndex: parseInt( sessionStorage.getItem( 'currentFlowIndex' ) ) || 0,
    currentFlowName: sessionStorage.getItem( 'currentFlowName' ) || "",
};

const flowSlice = createSlice( {
    name: 'flowSlice',
    initialState: initialStateForFlows,
    reducers: {
        setInitialFlowData: ( state, action ) => {
            const flowData = action.payload;
            const savedFlowName = sessionStorage.getItem( 'currentFlowName' );

            // Find the index of the block that matches the saved currentFlowName, if it exists
            let initialIndex = 0;
            if ( savedFlowName ) {
                const foundIndex = flowData.findIndex( block => block.block_type === savedFlowName );
                if ( foundIndex !== -1 ) {
                    initialIndex = foundIndex;
                }
            }

            // Set the initial flow data and state properties
            state.flowData = flowData;
            state.currentFlowIndex = initialIndex;
            state.currentFlowName = flowData.length > 0 ? flowData[initialIndex].block_type : "";

            // Persist flowData to sessionStorage
            sessionStorage.setItem( 'flowData', JSON.stringify( flowData ) );
            sessionStorage.setItem( 'currentFlowIndex', state.currentFlowIndex );
            sessionStorage.setItem( 'currentFlowName', state.currentFlowName );
        },
        nextFlow: ( state ) => {
            if ( state.flowData && state.flowData.length > 0 ) {
                if ( state.currentFlowIndex < state.flowData.length - 1 ) {
                    state.currentFlowIndex += 1;
                    state.currentFlowName = state.flowData[state.currentFlowIndex].block_type;
                    console.log( state.flowData );
                    console.log( state.flowData[state.currentFlowIndex] );
                    console.log( state.currentFlowIndex );
                    console.log( state.currentFlowName );

                    // Update sessionStorage
                    sessionStorage.setItem( 'currentFlowIndex', state.currentFlowIndex );
                    sessionStorage.setItem( 'currentFlowName', state.currentFlowName );
                }
            } else {
                console.error( "flowData is not initialized or is empty" );
            }
        },
        previousFlow: ( state ) => {
            if ( state.flowData && state.flowData.length > 0 ) {
                if ( state.currentFlowIndex > 0 ) {
                    state.currentFlowIndex -= 1;
                    state.currentFlowName = state.flowData[state.currentFlowIndex].block_type;

                    // Update sessionStorage
                    sessionStorage.setItem( 'currentFlowIndex', state.currentFlowIndex );
                    sessionStorage.setItem( 'currentFlowName', state.currentFlowName );
                }
            } else {
                console.error( "flowData is not initialized or is empty" );
            }
        },
        resetFlow: ( state ) => {
            state.currentFlowIndex = 0;
            state.currentFlowName = state.flowData.length > 0 ? state.flowData[0].block_type : "";

            // Update sessionStorage
            sessionStorage.setItem( 'currentFlowIndex', state.currentFlowIndex );
            sessionStorage.setItem( 'currentFlowName', state.currentFlowName );
        },
        goToFlow: ( state, action ) => {
            const index = action.payload;
            if ( state.flowData && index >= 0 && index < state.flowData.length ) {
                state.currentFlowIndex = index;
                state.currentFlowName = state.flowData[index].block_type;

                // Update sessionStorage
                sessionStorage.setItem( 'currentFlowIndex', state.currentFlowIndex );
                sessionStorage.setItem( 'currentFlowName', state.currentFlowName );
            }
        },
    },
} );

export const { setInitialFlowData, nextFlow, previousFlow, resetFlow, goToFlow } = flowSlice.actions;

// Export all reducers
export const studentReducer = studentSlice.reducer;
export const parentReducer = parentSlice.reducer;
export const schoolRegistrationReducer = schoolRegistration.reducer;
export const studentRegistrationReducer = studentRegistration.reducer;
export const currentQuestionReducer = currentQuestionSlice.reducer;
export const consentFormReducer = consentForm.reducer;
export const updateAnswerReducer = answersSlice.reducer;
export const formSubmittedReducer = formSubmitted.reducer;
export const loadingScreenReducer = loadingScreen.reducer;
export const requiredQuestionsAttemptedReducer = requiredQuestionsAttempted.reducer;
export const clientConfigurationReducer = clientConfiguration.reducer;
export const formConfigurationReducer = formConfiguration.reducer;
export const flowReducer = flowSlice.reducer;


