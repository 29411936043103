import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import { notification, Spin } from 'antd';
import useGeneratePdfReport from '../../api/useGeneratePdfReport';
import { useSelector } from 'react-redux';
import ThankYouPageOne from './ThankYouPageOne';

export const ThankYouStride = () => {

    const location = useLocation();
    const [studentRollNumber, setStudentRollNumber] = useState( null );

    const student_name = localStorage.getItem( 'studentName' ) || "abc";
    const student_email = localStorage.getItem( 'user_email' ) || "abc@gmail.com";

    const params = new URLSearchParams( location.search );
    const template_id = params.get( 'template_id' );

    const formConfigRedux = useSelector( state => state.formConfiguration );
    const formConfig = formConfigRedux?.form_config;
    const formid = formConfig?.form_id;

    const { data: pdfReport, isLoading: pdfReportGenerating, generatePdfReport } = useGeneratePdfReport();

    const handleBooking = () => {
        window.open( `https://bookings.strideahead.io/team-stride/mentorship-session/?name=${ student_name }&email=${ student_email }`, '_blank' );
    }

    useEffect( () => {
        if ( studentRollNumber ) {
            generatePdfReport( studentRollNumber, formid )
        }
    }, [studentRollNumber] )

    const handleDownloadReport = () => {
        if ( pdfReportGenerating ) {
            notification.info( {
                message: 'Please wait, we\'re generating your pdf report',
            } )
        } else {
            if ( !pdfReport ) return;
            window.open( pdfReport, '_blank' );
        }
    }

    useEffect( () => {
        const student_roll_number = localStorage.getItem( 'student_roll_number' ) || localStorage.getItem( 'user_id' )
        setStudentRollNumber( student_roll_number )
    }, [localStorage.getItem( 'student_roll_number' )] );

    return (
        <>
            <ThankYouPageOne
                handleReport={ handleDownloadReport }
                handlementorshipbooking={ handleBooking }
            />
        </>
    );
};

